import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import styles from './Logomark.module.scss';

export default function Logomark({
  type = 'default',
  url = '/',
}: {
  readonly type?: 'white' | 'default';
  readonly url?: string;
}) {
  const { basePath } = useRouter();

  return (
    <Link
      href={url}
      className={classNames(styles.logomark, {
        [styles[`logomark--${type}`]]: type,
      })}
    >
      <Image
        className={styles.logomark__img}
        src={`${basePath}/images/logo/${type}.svg`}
        width={182}
        height={36}
        alt="WRAP LA Portal"
      />
    </Link>
  );
}
