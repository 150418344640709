import { Skin, Spacing, Wrap } from '@etchteam/mobius';
import { ReactNode } from 'react';

import styles from './Footer.module.scss';
import FooterLinks from './Links';
import FooterSmallprint from './Smallprint';

export default function Footer({
  children,
}: {
  readonly children?: ReactNode;
}) {
  return (
    <Skin skin="dark">
      <footer className={styles.footer}>
        <Wrap>
          {children}
          <Spacing bottom="sm">
            <FooterLinks />
          </Spacing>
          <FooterSmallprint />
        </Wrap>
      </footer>
    </Skin>
  );
}
