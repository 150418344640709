import { Card, Enter } from '@etchteam/mobius';

import Item from './Item';
import Log from './Log';

import { usePreferences } from 'lib/hooks';

function RecentlyViewed() {
  const [preferences] = usePreferences();

  const recents = preferences.recent;

  if (!recents) {
    return null;
  }

  return (
    <Enter type="fade-in-up">
      <Card border>
        <Card.Header>Recently viewed</Card.Header>
        <Card.Body padded={false}>
          {recents.map((item, i) => (
            <Item slug={item} key={i} />
          ))}
        </Card.Body>
      </Card>
    </Enter>
  );
}

RecentlyViewed.Log = Log;

export default RecentlyViewed;
