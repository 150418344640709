import { useRouter } from 'next/router';
import { ReactNode, useEffect } from 'react';

import { useUser } from 'lib/hooks';

interface HeartBeatProps {
  readonly children?: ReactNode;
}

export default function HeartBeat({ children }: HeartBeatProps) {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, executeQuery] = useUser();

  function handleRouteChange() {
    executeQuery({ requestPolicy: 'cache-and-network' });
  }

  // Rerun the query when the route changes
  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, []);

  return <>{children}</>;
}
