import { Enter, Alert, Skin } from '@etchteam/mobius';
import { useRouter } from 'next/router';
import {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
  ReactNode,
} from 'react';
import { useTranslation } from 'react-i18next';

import styles from './GlobalAlerts.module.scss';

const GlobalAlerts = createContext<any>({});

function GlobalAlertsProvider({
  children,
}: Readonly<{ children?: ReactNode }>) {
  const [alerts, setAlerts] = useState([]);
  const router = useRouter();
  const on = router.events?.on;
  const off = router.events?.off;

  const removeNonPersistentAlerts = useCallback(() => {
    setAlerts(alerts.filter((alert) => alert.persist));
  }, [alerts]);

  useEffect(() => {
    // Remove all non-persistent alerts when the route changes
    on('routeChangeComplete', removeNonPersistentAlerts);

    return () => {
      off('routeChangeComplete', removeNonPersistentAlerts);
    };
  }, [on, off, removeNonPersistentAlerts]);

  const addAlert = (data: {
    title: string;
    message: string;
    type: 'neutral' | 'positive' | 'negative' | 'attention';
    persist?: boolean;
  }) => setAlerts([...alerts, data]);

  const removeAlert = (index) => {
    const updatedAlerts = [...alerts];
    updatedAlerts.splice(index, 1);
    setAlerts(updatedAlerts);
  };

  return (
    <GlobalAlerts.Provider
      value={{
        add: addAlert,
        get: alerts,
      }}
    >
      {children}
      {!!alerts.length && (
        <ul className={styles['global-alerts']}>
          {alerts.map((alert, i) => (
            <li className={styles['global-alerts__alert']} key={i}>
              <Enter type="fade-in-up">
                <Skin skin={alert.type}>
                  <Alert onClose={() => removeAlert(i)}>
                    {!!alert.title && <h4>{alert.title}</h4>}
                    <p>{alert.message}</p>
                  </Alert>
                </Skin>
              </Enter>
            </li>
          ))}
        </ul>
      )}
    </GlobalAlerts.Provider>
  );
}

export function useGlobalAlerts() {
  const context = useContext(GlobalAlerts);

  if (context === undefined) {
    throw new Error(
      'useGlobalAlerts must be used within a GlobalAlertsProvider',
    );
  }

  return context;
}

export function useGraphQLErrorAlert() {
  const { t } = useTranslation();
  const alerts = useContext(GlobalAlerts);

  return (error: Error) =>
    alerts.add({
      type: 'negative',
      title: t('common.errors.updateFailed'),
      message: error?.message?.replace('[GraphQL] ', '') || '',
    });
}

export default GlobalAlertsProvider;
