import { Wrap } from '@etchteam/mobius';
import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './TitleBarLoggedOut.module.scss';

export default function TitleBarLoggedOut({
  skin = 'transparent',
  children,
}: {
  readonly skin?: string;
  readonly children?: ReactNode;
}) {
  return (
    <header
      className={classNames(styles['title-bar-logged-out'], {
        [styles[`title-bar-logged-out--${skin}`]]: skin,
      })}
    >
      <Wrap size="xxxl">{children}</Wrap>
    </header>
  );
}
