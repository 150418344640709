import { useTranslation } from 'react-i18next';

import styles from './Footer.module.scss';

const FooterSmallprint = () => {
  const { t } = useTranslation();

  return (
    <p className={styles.footer__smallprint}>
      <small>{t('components.canvas.footer.smallprint')}</small>
    </p>
  );
};

export default FooterSmallprint;
