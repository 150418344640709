import * as Sentry from '@sentry/nextjs';
import { Component, ReactNode, createContext, useContext } from 'react';

import ErrorPage from '@/components/content/ErrorPage/ErrorPage';
import { User } from '@/types/user.type';

const GlobalErrors = createContext<{ onError?: (error: any) => void }>({});

interface ErrorBoundaryProps {
  readonly children?: ReactNode;
  readonly user?: User;
}

interface ErrorBoundaryState {
  error: {
    code?: string;
    title?: string;
    message?: string;
  };
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private static defaultError = {
    code: 'Oops',
    title: 'This is rubbish!',
    message: 'Something went wrong, our team are looking into it.',
  };

  constructor(props) {
    super(props);
    this.state = { error: null };

    this.onError = this.onError.bind(this);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      error: ErrorBoundary.defaultError,
    };
  }

  componentDidCatch(error, errorInfo: { [key: string]: any }) {
    const { user } = this.props;
    logError(error, errorInfo, user);
    this.setState({ error: ErrorBoundary.defaultError });
  }

  onError(error) {
    this.setState({ error });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      // You can render any custom fallback UI
      return (
        <ErrorPage statusCode={parseInt(error?.code ?? '404', 10)} {...error} />
      );
    }

    return (
      <GlobalErrors.Provider
        value={{
          onError: this.onError,
        }}
      >
        {children}
      </GlobalErrors.Provider>
    );
  }
}

export function logError(
  error: unknown,
  errorInfo: { [key: string]: unknown },
  user?: ErrorBoundaryProps['user'],
): void {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });

    scope.setUser({ id: user?.id.toString() });

    Sentry.captureException(error);
  });
}

export function useGlobalErrors() {
  const context = useContext(GlobalErrors);

  if (context === undefined) {
    throw new Error(
      'useGlobalErrors must be used within a GlobalErrorsProvider',
    );
  }

  return context;
}

export default ErrorBoundary;
