import i18n from 'lib/i18n';

export interface Tool {
  url: string;
  title: string;
  description: string;
  img?: string;
  disabled?: boolean;
}

const tools: Tool[] = [
  {
    url: '/benchmark',
    title: i18n.t('dashboard.tools.benchmarking.title'),
    description: i18n.t('dashboard.tools.benchmarking.description'),
    img: '/uk/images/hero/team-bins.png',
  },
  {
    url: '/scheme',
    title: i18n.t('dashboard.tools.search.title'),
    description: i18n.t('dashboard.tools.search.description'),
    img: '/uk/images/hero/pair-bin.png',
  },
  {
    url: '/costing-tool',
    title: i18n.t('dashboard.tools.costing.title'),
    description: i18n.t('dashboard.tools.costing.description'),
    img: '/uk/images/hero/pair-collection.png',
    disabled: process.env.NEXT_PUBLIC_KERBSIDE_COSTING_DISABLED === 'true',
  },
];

export default tools;
