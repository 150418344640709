import { Icon } from '@etchteam/mobius';
import classNames from 'classnames';
import Link from 'next/link';
import { ReactNode, forwardRef } from 'react';

import styles from './LinkArrow.module.scss';

interface LinkArrowProps {
  readonly children?: ReactNode;
  readonly href?: string;
  readonly onClick?: any;
  readonly border?: boolean;
}
const LinkArrow = forwardRef(
  ({ children, href, onClick, border = false }: LinkArrowProps, ref: any) => (
    <Link
      ref={ref}
      href={href}
      onClick={onClick}
      className={classNames(styles['link-arrow'], {
        [styles['link-arrow--disabled']]: !href,
        [styles['link-arrow--border']]: border,
      })}
    >
      <span className={styles['link-arrow__content']}>{children}</span>
      <span className={styles['link-arrow__icon']}>
        <Icon icon="arrow-right" />
      </span>
    </Link>
  ),
);

LinkArrow.displayName = 'LinkArrow';

export default LinkArrow;
