import classNames from 'classnames';
import { ReactNode } from 'react';

import styles from './TitleBar.module.scss';

export default function TitleBar({
  skin = 'forest',
  children,
}: {
  readonly skin?: 'forest' | 'muted';
  readonly children?: ReactNode;
}) {
  return (
    <header
      className={classNames(styles['title-bar'], {
        [`${styles[`title-bar--${skin}`]}`]: skin,
      })}
    >
      <div className={styles['title-bar__inner']}>{children}</div>
    </header>
  );
}
