import Head from 'next/head';

export default function PageTitle({ title }: { readonly title?: string }) {
  return (
    <Head>
      {title ? (
        <title>{title} | WRAP LA Portal</title>
      ) : (
        <title>WRAP LA Portal</title>
      )}
    </Head>
  );
}
